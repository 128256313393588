jQuery(document).ready(function ($) {

    $('.burger-toggle').click(function(){
        var target = $(this).attr('data-target');
        $(target).slideToggle(100,'swing',function(){
            ($(this).hasClass('collapse'))? $(this).removeClass('collapse') : $(this).addClass('collapse');
            $(this).removeAttr('style');
        });
    });

    $('#main-menu').find('.expanded').each(function(index, el) {
        $(this).append('<button class="ul-toggle">+</button>');
    });

    $('.ul-toggle').on('click',  function(event) {
        event.preventDefault();
        if($(this).text() == '+') {
            $(this).text('-');
        } else {
            $(this).text('+');
        }
        $(this).siblings('.menu').toggleClass('collapse');
    });

    var $body = $('body');

    if ($body.hasClass('node-type-page') || $body.hasClass('node-type-news') || $body.hasClass('node-type-featured-article')) {

        $(window).load(function () {

            /*
             Two image styles for expandable images:
             1. inline_xl_expandable which use a light box to display
             the full size image (see magnific popup js/css elsewhere)
             2. inline_expandable which expand inline in the content (thwoopable!)
             */

            // Make [data-picture-mapping="inline_expandable"] images thwoopable

            var thwoopImageSelector = 'picture[data-picture-mapping="inline_expandable"]';
            var $thwoopImages = $(thwoopImageSelector);

            $thwoopImages.each(function () {
                var $childImg = $(this).find('img');
                var imgSrc = $childImg.attr('src');
                if (!imgSrc)
                    imgSrc = $childImg.attr('srcset');

                var preThwoopWidth = $(this).width();
                var preThwoopHeight = $(this).height();

                $(this).wrap('<a class="thwooper" title="expand image" href="' + imgSrc + '" target="_blank" data-picture-origwidth="' + preThwoopWidth + '" data-picture-origheight="' + preThwoopHeight + '"></a>');
                $(this).parent('.thwooper').append('<span class="thwoop-icon" />');
            });

            $('.thwooper').css('transition', 'none') // remove css transition which prevents jquery animating
                .click(function (event) {

                    // Prevent default click event (going to the link URL)
                    event.preventDefault();

                    var $thwooper = $(this);
                    var preThwoopWidth = $(this).attr('data-picture-origwidth');
                    var preThwoopHeight = $(this).attr('data-picture-origheight');

                    var $picture = $(this).find(thwoopImageSelector);
                    var $thwoopIcon = $(this).find('.thwoop-icon');

                    // Are thwoopers expanded?
                    if (!$thwooper.hasClass('thwooped')) { // No

                        //$thwooper.addClass('thwooped');

                        var maxThwoopWidth = $picture.css('max-width');

                        if (maxThwoopWidth != "none") {
                            maxThwoopWidth = parseInt(maxThwoopWidth.substr(0, maxThwoopWidth.indexOf('px')), 10);
                        }

                        if (maxThwoopWidth > $thwooper.parent().width())
                            maxThwoopWidth = $thwooper.parent().width();

                        // Thwoop the image
                        var width = $picture.width();
                        var height = $picture.height();
                        var ratio = width / height;
                        var maxThwoopHeight = Math.floor(maxThwoopWidth / ratio);

                        // do the thwoop
                        if (width < maxThwoopWidth) {

                            $picture.animate({
                                    width: maxThwoopWidth,
                                    height: maxThwoopHeight
                                },
                                {
                                    duration: 250,
                                    easing: "swing",
                                    start: function () {
                                        $thwooper.addClass('thwooped');
                                        $thwoopIcon.hide();
                                    },
                                    complete: function () {
                                        $thwoopIcon.addClass('shrink').show();
                                        $thwooper.attr('title', 'shrink image');

                                    }
                                });

                        }

                    } else {

                        $picture.animate({
                                width: preThwoopWidth,
                                height: preThwoopHeight
                            },
                            {
                                duration: 250,
                                easing: "swing",
                                start: function () {
                                    $thwoopIcon.hide();
                                },
                                complete: function () {
                                    $thwooper.removeClass('thwooped');
                                    $thwooper.attr('title', 'expand image');
                                    $thwoopIcon.removeClass('shrink').show();
                                    $(this).attr('style', '');
                                }
                            });
                    }
                });
        });
    }

    // would prefer the New by date to be sorted by year which collapses to show months
    if (document.getElementById("block-views-news-by-date-block-2") && document.getElementById("block-views-news-by-date-block-1")) {
        var blockMonths = document.getElementById("block-views-news-by-date-block-2"),
            blockYears = document.getElementById("block-views-news-by-date-block-1"),
            monthList = blockMonths.querySelectorAll(".views-summary li"),
            yearList = blockYears.querySelectorAll(".views-summary li");

        // Create an UL element inside each year list
        for (var h = yearList.length - 1; h >= 0; h--) {
            var ul = document.createElement("ul");
            yearList[h].appendChild(ul);
        }
        // Loop over month/year list and append months inside corresponding years
        for (var i = monthList.length - 1; i >= 0; i--) {
            var month = monthList[i].getElementsByTagName("a")[0].innerHTML.split("/");
            for (var f = yearList.length - 1; f >= 0; f--) {
                var year = yearList[f].getElementsByTagName("a")[0].innerHTML;
                if (month[1] == year) {
                    var finalMonth = monthList[i].getElementsByTagName("a")[0].innerHTML.split("/")[0];
                    monthList[i].getElementsByTagName("a")[0].innerHTML = finalMonth;
                    yearList[f].getElementsByTagName("ul")[0].appendChild(monthList[i]);
                }
            }
        }
        // Toogle list using jQuery
        if ($(yearList).has("ul")) {
            $(yearList).each(function () {
                $(this).prepend("<i class='fa fa-plus-square-o'></i>");
                $(this).children('i').click(function (event) {
                    $(this).siblings("a").toggleClass('active');
                    $(this).siblings('ul').toggle();
                    if ($(this).hasClass('fa-plus-square-o')) {
                        $(this).removeClass('fa-plus-square-o').addClass('fa-minus-square-o');
                    } else {
                        $(this).removeClass('fa-minus-square-o').addClass('fa-plus-square-o');
                    }
                });
            });
        }
    }

});
